import { apiClient as axios } from './axiosAdapter'

const API_URL = 'cars/'

class CarsService {
  loadCars() {
    return axios.get(API_URL)
  }
  loadNumberCars() {
    return axios.get(API_URL + 'numbers/')
  }
  getCarByNumberOrId(identifier) {
    // Определяем, что использовать: номер или id
    const params =
      typeof identifier === 'string'
        ? { number: identifier } // Если строка, это номер
        : { id: identifier } // Если число, это id

    return axios.get(API_URL + `by-number/`, { params })
  }
  updateCarInfo(form) {
    return axios.put(API_URL + 'change/', form)
  }
  loadCarComplaints(carId) {
    return axios.get(API_URL + `complaints/${carId}/`)
  }
  addCarComplaint(form) {
    return axios.post(API_URL + 'complaints/add/', form)
  }
  removeCarComplaint(complaintId) {
    return axios.post(API_URL + 'complaints/delete/', { id: complaintId })
  }
  addCarTechEvent(form) {
    return axios.post(API_URL + 'tech_events/add/', form)
  }
  updateCarTechEvent(form) {
    return axios.put(API_URL + 'tech_events/change/', form)
  }
  removeCarTechEvent(id) {
    return axios.post(API_URL + `tech_events/delete/`, { id })
  }
  addCarTripEvent(form) {
    return axios.post(API_URL + 'trip_events/add/', form)
  }
  updateCarTripEvent(form) {
    return axios.put(API_URL + 'trip_events/change/', form)
  }
  removeCarTripEvent(tripId) {
    return axios.post(API_URL + `trip_events/delete/`, { id: tripId })
  }
  addCarTripInfoEvent(form) {
    return axios.post(API_URL + 'trip_events_info/add/', form)
  }
  updateCarTripInfoEvent(tripInfoId, updatedData) {
    return axios.put(API_URL + 'trip_events_info/change/', {
      id: tripInfoId,
      ...updatedData
    })
  }
  getTripById(tripId) {
    return axios.get(`${API_URL}trip_events/get-by-id/`, {
      params: { id: tripId }
    })
  }
  removeCarTripEventInfoEvent(ids) {
    return axios.post(API_URL + `trip_events_info/delete/`, { id: ids })
  }
  sendEmail(data) {
    return axios.post(API_URL + `send-mail/send_mail/`, data)
  }
  sendEmailDriver(data) {
    return axios.post(API_URL + `send-mail/send_mail_driver/`, data)
  }
}

export default new CarsService()
