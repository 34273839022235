import CarsService from '@/services/cars.service'

const initialState = {
  selectedCar: null,
  loading: false,
  tripEventLoading: false,
  techEventLoading: false,
  cars: [],
  numberCars: [],
  techEventsTypes: [],
  tripEventsTypes: [],
  deepCopiedData: [],
  selectedTripInfo: null // Список trip_info для выбранной поездки
}

export const cars = {
  namespaced: true,
  state: initialState,
  actions: {
    loadCars({ commit }) {
      commit('carsLoading', true)
      return CarsService.loadCars().then(
        (data) => {
          commit('carsLoadSuccess', data)
          return Promise.resolve(data)
        },
        (error) => {
          commit('carsLoadFailed')
          return Promise.reject(error)
        }
      )
    },
    loadNumberCars({ commit }) {
      commit('carsLoading', true)
      return CarsService.loadNumberCars().then(
        (data) => {
          commit('NumberCarsLoadSuccess', data)
          return Promise.resolve(data)
        },
        (error) => {
          commit('NumberCarsLoadFailed')
          return Promise.reject(error)
        }
      )
    },
    getCar({ commit }, identifier) {
      commit('carsLoading', true) // Устанавливаем флаг загрузки
      commit('setTripEventLoading', true)
      commit('setTechEventLoading', true)
      return CarsService.getCarByNumberOrId(identifier)
        .then(
          (data) => {
            const deepCopiedData = JSON.parse(JSON.stringify(data))
            commit('setSelectedCar', data) // Устанавливаем выбранную машину
            commit('setInitialCarData', deepCopiedData) // Сохраняем первоначальные данные для последующего сравнения
            return Promise.resolve(data)
          },
          (error) => {
            commit('carLoadFailed') // Загрузка завершилась с ошибкой
            return Promise.reject(error)
          }
        )
        .finally(() => {
          // Сбрасываем флаги загрузки
          commit('carsLoading', false)
          commit('setTripEventLoading', false)
          commit('setTechEventLoading', false)
        })
    },
    updateCarInfo({ commit }, form) {
      return CarsService.updateCarInfo(form).then((response) => {
        commit('setMessage', { text: 'carInfoUpdateSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    addCarTechEvent({ commit }, form) {
      return CarsService.addCarTechEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventAddSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    updateCarTechEvent({ commit }, form) {
      return CarsService.updateCarTechEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventUpdateSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    removeCarTechEvent({ commit }, id) {
      return CarsService.removeCarTechEvent(id).then((response) => {
        commit('setMessage', { text: 'carEventRemoveSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    removeCarComplaint({ commit }, complaintId) {
      return CarsService.removeCarComplaint(complaintId).then((response) => {
        commit('setMessage', { text: 'carComplaintRemoveSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    addCarComplaint({ commit }, form) {
      return CarsService.addCarComplaint(form).then((response) => {
        commit('setMessage', { text: 'carComplaintAddSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    addCarTripEvent({ commit }, form) {
      return CarsService.addCarTripEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventAddSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    removeCarTripEvent({ commit }, tripId) {
      return CarsService.removeCarTripEvent(tripId).then((response) => {
        commit('setMessage', { text: 'carEventRemoveSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    updateCarTripEvent({ commit }, form) {
      return CarsService.updateCarTripEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventUpdateSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    addCarTripEventInfo({ commit }, form) {
      return CarsService.addCarTripInfoEvent(form).then((response) => {
        return Promise.resolve(response)
      })
    },
    UpdateCarTripEventInfo({ commit }, { tripInfoId, updatedData }) {
      commit('carsLoading', true) // Устанавливаем флаг загрузки
      return CarsService.updateCarTripInfoEvent(tripInfoId, updatedData)
        .then((response) => {
          commit('carsLoading', false) // Снимаем флаг
          return Promise.resolve(response)
        })
        .catch((error) => {
          commit('carsLoading', false) // Снимаем флаг
          return Promise.reject(error)
        })
    },
    getCarTripInfo({ commit }, tripId) {
      commit('setTripEventLoading', true)
      return CarsService.getTripById(tripId)
        .then((response) => {
          const tripInfo = response.trip_info // Извлекаем массив trip_info
          commit('setCarTripInfo', tripInfo) // Сохраняем данные в state

          return Promise.resolve(tripInfo)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
        .finally(() => {
          commit('setTripEventLoading', false)
        })
    },
    removeCarTripEventInfo({ commit }, tripInfoIds) {
      return CarsService.removeCarTripEventInfoEvent(tripInfoIds).then((response) => {
        return Promise.resolve(response)
      })
    },
    sendMessages({ commit }, requestData) {
      return CarsService.sendEmail(requestData).then((response) => {
        commit('setMessage', { text: 'carMessageSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    sendMessagesDriver({ commit }, requestData) {
      return CarsService.sendEmailDriver(requestData).then((response) => {
        commit('setMessage', { text: 'carMessageSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    }
  },
  mutations: {
    setInitialCarData(state, data) {
      state.localTripInfo = data // Сохраняем изолированную копию данных
    },
    setTripEventLoading(state, isLoading) {
      state.tripEventLoading = isLoading
    },
    setTechEventLoading(state, isLoading) {
      state.techEventLoading = isLoading
    },
    carsLoading(state, status) {
      state.loading = status
    },
    setCarTripInfo(state, tripInfo) {
      state.selectedTripInfo = tripInfo // Сохраняем данные в state
    },
    carsLoadSuccess(state, data) {
      state.loading = false
      state.cars = data.cars
      state.techEventsTypes = data.tech_events_types
      state.tripEventsTypes = data.trip_events_types
    },
    setSelectedCar(state, data) {
      state.loading = false
      state.selectedCar = data.car
      state.techEventsTypes = data.tech_events_types
      state.tripEventsTypes = data.trip_events_types
    },
    NumberCarsLoadSuccess(state, data) {
      state.loading = false
      state.numberCars = data // Прямо записываем массив машин
    },
    NumberCarsLoadFailed(state) {
      state.loading = false
      state.numberCars = []
    },
    carsLoadFailed(state) {
      state.loading = false
      state.cars = []
    }
  }
}
